<template>
  <div>
    <top :shouback="true" />
    <div class='container flex color-f'>
      <!-- 左边的盒子 -->
      <div class="box_l flex1 h-max pt37 color-f">
        <div class="mb36 pl22">
          <shebeiType :list='shebieList' />
        </div>
        <!-- 消毒机使用情况 -->
        <div class="pl30">
          <!-- 标题 -->
          <div class="header_title fs16">
            <div class="yuan"></div>
            <div class="header_ch">消毒机使用情况</div>
            <div class="header_en"> {{'Usage of disinfector'.toUpperCase()}} </div>
          </div>
          <div class="pr138">
            <xiaodujiState class="mt16" />
          </div>
        </div>
        <!-- //消毒机使用情况 -->
        <!-- 健康码检测情况 -->
        <div class="mt30 pl30">
          <!-- 标题 -->
          <div class="header_title fs16">
            <div class="yuan"></div>
            <div class="header_ch">健康码检测情况</div>
            <div class="header_en">{{'Health code detection'.toUpperCase()}}</div>
          </div>
          <div class="pr141">
            <healthyCode class="mt20" @getTestNum='getTestNum' />
          </div>

        </div>
        <!-- //健康码检测情况 -->

        <!-- 体温数据监测 -->
        <div class="mt37 pl30">
          <!-- 标题 -->
          <div class="header_title fs16">
            <div class="yuan"></div>
            <div class="header_ch">体温数据监测</div>
            <div class="header_en">{{'Temperature data monitoring'.toUpperCase()}}</div>
          </div>
          <div class="pr126">
            <tiwenData />
          </div>
        </div>
        <!-- //体温数据监测 -->
      </div>
      <!-- 左边的盒子 -->

      <!-- 中间的盒子 -->
      <div class="box_center w488 h-max mr10 ml10">
        <img src="@/assets/img/4.0/topHeader.png" class="w330 h12 mt23" style="margin:0 auto">
        <!-- 今日检测人数 -->
        <div class="mt30 w-max countNum relative" style="z-index:2">
          <CountFlop style="margin:0 auto" :val='testNum' title='今日检测人数' suffix='次' />
        </div>
        <!-- //今日检测人数 -->

        <!-- 中间旋转区域 -->

        <div :class="[showQiRongCenter?'':'center_rotate']">
          <!-- v-if="lookType.indexOf(6)>-1" -->
          <div class="h548 relative mr10 mt28 center_bg" v-if="showQiRongCenter">
            <QieongjiaoCenter />
          </div>
          <centerRotate :list='rotateList' v-else></centerRotate>
        </div>
        <!-- //中间旋转区域 -->

        <!-- 核酸时间监测 -->
        <div :class="['center_b relative',showQiRongCenter?'':'mt_80']" style="z-index:2">
          <centerBottom />
        </div>
        <!-- //核酸时间监测 -->
      </div>
      <!-- //中间的盒子 -->

      <!-- 右边的盒子 -->
      <div :class="['box_r flex1 h-max pt57 mr26',showQiRongCenter?'mt_20':'']">
        <!-- 实时保护情况 -->
        <div class="pl73 pr22">
          <div class="header_title fs16">
            <div class="yuan"></div>
            <div class="header_ch">实时保护情况</div>
            <div class="header_en">{{'Real time protection'.toUpperCase()}}</div>
          </div>
          <!-- 组件 -->
          <div class="mt6">
            <defendStateList />
          </div>
        </div>
        <!-- //实时保护情况 -->

        <!-- 近一周消毒时长 -->
        <div class="pl74 pr22 mt20">
          <div class="header_title fs16 mb6">
            <div class="yuan"></div>
            <div class="header_ch">近一周消毒时长</div>
            <div class="header_en">{{'Duration of disinfection in recent week'.toUpperCase()}}</div>
          </div>
          <!-- 组件 -->
          <div>
            <xiaoduArea />
          </div>
          <!-- //组件 -->
        </div>
        <!-- //近一周消毒时长 -->

        <!-- 设备位置监测 -->
        <div class="pl103 pr22 mt20">
          <div class="header_title fs16 mb6">
            <div class="yuan"></div>
            <div class="header_ch">设备位置监测</div>
            <div class="header_en">{{'Equipment position monitoring'.toUpperCase()}}</div>
          </div>
          <!-- 组件 -->
          <div>
            <!-- <hexiaoList /> -->
            <AddListener :list='list' ref="addDom" />
          </div>
          <!-- //组件 -->
        </div>
        <!-- //设备位置监测 -->

        <!-- 机器人使用情况 -->
        <div class="pl103 pr22 mt20">
          <div class="header_title fs16 mb6">
            <div class="yuan"></div>
            <div class="header_ch">机器人使用情况</div>
            <div class="header_en">{{'Robot usage'.toUpperCase()}}</div>
          </div>
          <!-- 组件 -->
          <div class="mt7">
            <robotState :list='list' />
          </div>
          <!-- //组件 -->
        </div>
        <!-- //机器人使用情况 -->
      </div>
      <!-- //右边的盒子 -->
    </div>
  </div>

</template>

<script>
import top from '@/components/top.vue'
import shebeiType from '@/components/shebeiType'
import xiaodujiState from '@/components/xiaodujiState'
import healthyCode from '@/components/healthyCode'
import tiwenData from '@/components/tiwenData'
import defendStateList from '@/components/defendStateList'
import xiaoduArea from '@/components/xiaoduArea'
// import hexiaoList from '@/components/hexiaoList'
import robotState from '@/components/robotState'
// import countNum from '@/components/countNum'
import centerRotate from '@/components/centerRotate'
import centerBottom from '@/components/centerBottom'
import CountFlop from '@/components/CountFlop'
import AddListener from './components/addListener.vue'
import { getNetLocation } from '@/api/Robot'
import clearTime from '@/mixin/clearTime.js'
import QieongjiaoCenter from './components/qieongjiaoCenter.vue'
// import updateStatus from '@/mixin/updateStatus.js'  //向后端获取天青数据
//引入补0的方法
// import { toOrderNum } from '@/utils/pubilcjs'

export default {
  name: 'AllPanle',
  mixins: [clearTime],
  components: {
    top, shebeiType, xiaodujiState, healthyCode,
    tiwenData, defendStateList, xiaoduArea,
    robotState, CountFlop, centerRotate, centerBottom, AddListener, QieongjiaoCenter
  },
  data () {
    return {
      //今日检测人数
      testNum: '',
      //中间旋转区域文字
      rotateList: [{
        title: '＞99.99',
        company: '%',
        type: '新冠病毒'
      }, {
        title: '99.95',
        company: '%',
        type: '白葡萄球菌'
      }, {
        title: '93.01',
        company: '%',
        type: '自然菌'
      }, {
        title: '99.94',
        company: '%',
        type: 'H1N1病毒'
      }, {
        title: '99.41',
        company: '%',
        type: '人冠状病毒'
      },],
      list: [],  //设备位置检测   机器使用情况,
      lookType: sessionStorage.getItem('lookType')
    };
  },

  created () {
    this.getNetLocation()
    this.timer = setInterval(() => {
      this.getNetLocation()
    }, 180000)
  },
  computed: {
    //中间的气溶胶显示
    showQiRongCenter () {
      return true
      // return this.lookType.indexOf('6') > -1
    }
  },

  mounted () { },

  methods: {
    //健康码检测情况这个子组件传过来的今日检测人数
    getTestNum (e) {
      this.toOrderNum(e)
    },
    //转换千分位
    toOrderNum (num) {
      num = num.toString()
      if (num.length < 7) {
        num = '0' + num
        this.toOrderNum(num)
      } else {
        //加逗号
        num = num.slice(1, 4) + ',' + num.slice(4, 7)
        this.testNum = num
      }
    },
    //设备位置检测
    async getNetLocation () {
      const { data } = await getNetLocation()
      data.forEach(item => {
        if (item.parameter7 == 0) {
          item.state = '未运行'
        } else if (item.parameter7 == 1) {
          item.state = '运行中'
        } else {
          item.state = '未知'
        }
      })
      this.list = data
      this.$nextTick(() => {
        if (this.$refs.addDom) {
          this.$refs.addDom.setName()
        }
      })
    }
  }
}

</script>
<style lang='scss' scoped>
.container {
  width: 100%;
  // height: 1004px;
  background: #000;
  perspective: 1800px;
  .box_l {
    transform: rotateY(10deg);
    background: url("~@/assets/img/4.0/box_l.png") no-repeat;
    // background-size: contain;
    background-size: 100% 100%;
  }
  .box_r {
    transform: rotateY(-10deg);
    background: url("~@/assets/img/4.0/box_r.png") no-repeat;
    background-size: 100% 100%;
    // background-size: contain;
  }
  .center_rotate {
    margin-top: -70px;
  }
  .center_bg {
    background: url("~@/assets/img/4.0/center/center_bg.png") no-repeat;
  }
  .center_b {
    margin-left: -70px;
  }
  .mt_80 {
    margin-top: -80px;
  }
  .mt_20 {
    margin-top: -20px;
  }
}
</style>