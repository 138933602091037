<template>
  <div class=''>
    <div>
      <!-- 头部标题 -->
      <div class="herder_box flex col-center row-between pl16 pr16">
        <i class="el-icon-arrow-left"></i>
        <div class="fs16 fm-f color-f">{{name}}</div>
        <i class="el-icon-arrow-right"></i>
      </div>
      <!-- //头部标题 -->
      <!-- 内容 -->
      <el-carousel height="170px" indicator-position='none' @change='changeCarousel'>
        <el-carousel-item v-for="(item,i) in list" :key="i">
          <div class="mt16 flex  row-around">
            <div>
              <!-- 虚线球 -->
              <div class="dashed_box flex row-center col-center">
                <!-- 实线球 -->
                <div class="solid_box flex col-center row-center">
                  {{item.parameter4}}
                </div>
                <!-- //实线球 -->
              </div>
              <!-- //虚线球 -->
              <div class="info_box text-center mt5">
                当前楼层
              </div>
            </div>
            <div>
              <!-- 虚线球 -->
              <div class="dashed_box flex row-center col-center">
                <!-- 实线球 -->
                <div class="solid_box flex col-center row-center solid_box_text">
                  {{item.parameter6||'未知'}}
                </div>
                <!-- //实线球 -->
              </div>
              <!-- //虚线球 -->
              <div class="info_box text-center mt5">
                当前经度
              </div>
            </div>
            <div>
              <!-- 虚线球 -->
              <div class="dashed_box flex row-center col-center">
                <!-- 实线球 -->
                <div class="solid_box flex col-center row-center solid_box_text">
                  {{item.parameter5||'未知'}}
                </div>
                <!-- //实线球 -->
              </div>
              <!-- //虚线球 -->
              <div class="info_box text-center mt5">
                当前纬度
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <!-- //内容 -->
    </div>

  </div>
</template>

<script>
export default {
  name: 'addListener',
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      name: ''
    };
  },

  created () { },

  mounted () { },

  methods: {
    setName () {
      this.name = this.list[0].name
    },
    changeCarousel (i) {
      this.name = this.list[i].name
    }
  }
}

</script>
<style lang='scss' scoped>
.herder_box {
  // width: 566px;
  height: 36px;
  background: rgba(58, 242, 156, 0.3);
  border-radius: 5px;
}
.dashed_box {
  width: 110px;
  height: 110px;
  border: 2px dashed #1dbd72;
  border-radius: 50%;
  .solid_box {
    width: 94px;
    height: 94px;
    border: 2px solid #1dbd72;
    border-radius: 50%;
    font-size: 48px;
    font-family: DS-Digital-Bold, DS-Digital;
    font-weight: bold;
    color: #3af29c;
    text-shadow: 0px 0px 3px #3af29c;
  }
  .solid_box_text {
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
  }
}
.info_box {
  width: 118px;
  height: 30px;
  background: linear-gradient(
    360deg,
    rgba(29, 189, 114, 0.47) 0%,
    rgba(29, 189, 114, 0) 100%
  );
  border-bottom: 2px solid rgba(29, 189, 114, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1dbd72;
}
::v-deep .el-carousel__arrow {
  display: none;
}
</style>