<template>
  <div class='app_table fs16'>
    <div class="tabListHeader pl32 pr27 flex col-center">
      <div class="tabListHeader_after"></div>
      <div class="w210">设备名称</div>
      <div class="w200 text-center">持续保护时长(h)</div>
      <div class="flex1 text-right">保护率(%)</div>
    </div>
    <div class="h150 pl32 pr27" style="overflow: hidden;">
      <div class="roll_box" :style="{'--updateStyle':speend+'s'}" v-if="list.length>0">
        <div class="flex col-center h40 lh40 pl6 app_table_item" v-for="(item,i) in list" :key="i">
          <div class="w210 text-center flex col-center">
            <div class="w114 tab_name"> {{item.defendPeriod}}</div>
            ：
            <div class="flex1 text-left"> {{parseInt(item.greenNum/3600)}}h</div>
          </div>
          <div class="w200 ml16">
            <el-progress
              :percentage="item.percentage"
              :show-text='false'></el-progress>
          </div>
          <div class="flex1 text-right">{{item.percentage}}%</div>
        </div>
      </div>
      <div class="text-center lh110" v-else>暂无数据</div>
    </div>
  </div>
</template>

<script>
import { nowUseTime } from "@/api/wang.js"
import clearTime from '@/mixin/clearTime.js'
export default {
  name: 'defendStateList',
  mixins: [clearTime],
  data () {
    return {
      list: [],
      speend: null
    };
  },

  created () {
    this.nowUseTime()
    this.timer = setInterval(() => {
      this.nowUseTime()
    }, 180000)
  },

  mounted () { },

  methods: {
    async nowUseTime () {
      const { data } = await nowUseTime()
      data.forEach(item => {
        if (item.greenNum / 3600 >= 2) {
          item.percentage = 99.9
        } else {
          item.percentage = parseInt(item.greenNum / 3600 / 2 * 100)
        }
      })
      if (data.length >= 3) {
        this.list = [...data, ...data]
        this.speend = this.list.length
      } else {
        this.list = data
        this.speend = null
      }
    }
  }
}

</script>
<style lang='scss' scoped>
::v-deep .el-progress-bar__inner {
  background: linear-gradient(270deg, #f7da65 0%, #07ba74 99%);
}
// .bg {
//   background: linear-gradient(
//     90deg,
//     rgba(19, 250, 239, 1) 0%,
//     rgba(8, 244, 218, 1) 100%
//   );
//   border-radius: 5px;
// }
// .app_table_item:nth-child(even) {
//   background: linear-gradient(
//     90deg,
//     rgba(19, 250, 239, 1) 0%,
//     rgba(8, 244, 218, 1) 100%
//   );
//   border-radius: 5px;
// }
.roll_box {
  animation: move var(--updateStyle) linear infinite;
  .tab_name {
    text-align: justify;
    text-align-last: justify;
    text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
    white-space: nowrap; /* 强制文本在一行中显示 */
    overflow: hidden; /* 溢出隐藏*/
    text-overflow: ellipsis; /* 文本超出后显示省略号*/
  }
}
@keyframes move {
  0% {
  }

  100% {
    transform: translateY(-50%);
  }
}
.roll_box:hover {
  animation-play-state: paused;
}
</style>