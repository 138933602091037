<template>
  <div class='box'>
    <!-- 横向进度条 -->
    <div class="Progress_box  mb34">
      <div class="flex row-between mb13 fs16 col-bottom">
        <div class="flex col-center ">
          <div class="fw-600 fs16">运行中设备数：</div>
          <div class="fw-600 fs18" style="color:#F5DA65">{{runNUm}}台</div>
        </div>
        <div class="">消毒机总量{{total}}台</div>
      </div>
      <el-progress :percentage="(runNUm/total)*100" :show-text='false'></el-progress>
    </div>

    <!-- 圆环进度条区域 -->
    <div class="flex row-between">
      <div class="flex row-column row-center col-center totalTime">
        <el-progress type="circle" percentage="100" color='#3AC8B7' :format="format"></el-progress>
        <div class="mt5 mb14" style="color:#3AC8B7">累计开机总时长</div>
        <img src="@/assets/img/4.0/footerline_q.png" class="w120 h30 mt-28">
      </div>
      <div class="flex row-column row-center col-center progress_item1">
        <el-progress type="circle" :percentage="dengVal" color='#1DBD72'></el-progress>
        <div class="mt5 mb14" style="color:#1DBD72">等离子体消毒机</div>
        <img src="@/assets/img/4.0/footerline_g.png" class="w120 h30 mt-28">
      </div>
      <div class="flex row-column row-center col-center progress_item2">
        <el-progress type="circle" :percentage="shuiVal" color='#3890DC'></el-progress>
        <div class="mt5 mb14" style="color:#3890DC">水离子消毒机</div>
        <img src="@/assets/img/4.0/footerline_b.png" class="w120 h30 mt-28">
      </div>
    </div>
  </div>
</template>

<script>
import { totalTime, getBind } from '@/api/wang.js'
import clearTime from '@/mixin/clearTime.js'
export default {
  name: 'xiaodujiState',
  mixins: [clearTime],
  data () {
    return {
      totalHours: '',
      total: '',
      runNUm: '',
      dengVal: '',
      shuiVal: ''
    };
  },

  created () {
    //获取消毒机使用情况
    this.totalTime()
    this.getBind()
    this.timer = setInterval(() => {
      this.totalTime()
      this.getBind()
    }, 180000)
  },

  mounted () { },

  methods: {
    async totalTime () {
      const res = await totalTime()
      this.totalHours = parseInt(res.data.greenNum / 3600)
      //redNum水离子  yellowNum等离子体
      this.dengVal = parseInt(res.data.yellowNum / res.data.greenNum * 100)
      this.shuiVal = 100 - parseInt(res.data.yellowNum / res.data.greenNum * 100)
    },
    async getBind () {
      const { data } = await getBind()
      this.total = data[0].greenNum + data[1].greenNum
      this.runNUm = data[0].yellowNum + data[1].yellowNum
    },
    format () {
      return this.totalHours + 'h'
    }
  }
}

</script>
<style lang='scss' scoped>
.Progress_box {
  ::v-deep .el-progress-bar__inner {
    background: linear-gradient(270deg, #f7da65 0%, #07ba74 99%);
  }
}
.totalTime {
  ::v-deep .el-progress__text {
    font-size: 20px !important;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600 !important;
    color: #08f4da !important;
  }
}
::v-deep .el-progress-circle {
  width: 100px !important;
  height: 100px !important;
}
.progress_item1 {
  ::v-deep .el-progress__text {
    font-size: 20px !important;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600 !important;
    color: #3af29c;
  }
}
.progress_item2 {
  ::v-deep .el-progress__text {
    font-size: 20px !important;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600 !important;
    color: #5daef3 !important;
  }
}
.mt-28 {
  margin-top: -28px;
}
// 圆环边框的背景颜色
::v-deep .el-progress-circle__track {
  stroke: rgba(255, 255, 255, 0.2);
}
</style>