<template>
  <div class="tixing pt16">
    <!-- 标题 -->
    <div class="flex col-center title_box row-center">
      <div class="title_l"></div>
      <div class="title_test ml16 mr16">气溶胶粒子实时数据监测</div>
      <div class="title_l"></div>
    </div>
    <el-carousel trigger="click" indicator-position='none' @change='change' :interval='3000'>
      <el-carousel-item v-for="(item,i) in list" :key="i+'j'">
        <!-- 房间名 -->
        <div class="roomName_box">
          <i class="el-icon-arrow-left" style="color:rgba(255, 255, 255, .3)"></i>
          <div class="room_name">{{item.roomName}}</div>
          <i class="el-icon-arrow-right" style="color:rgba(255, 255, 255, .3)"></i>
        </div>
      </el-carousel-item>
    </el-carousel>

    <!-- 第三层 -->
    <div class="floor_three">
      <div class="floor_three_item">
        <div class="color-f fs16 color-f fw-500">总气溶胶粒子个数(个/s)</div>
        <!-- <div class="floor_three_num">{{item.parOrAll}}</div> -->
        <div class="floor_three_num">{{parOrAll}}</div>

      </div>
      <div class="floor_three_item">
        <div class="color-f fs16 color-f fw-500">总荧光粒子数(个/s)</div>
        <!-- <div class="floor_three_num">{{item.bioOrAll}}</div> -->
        <div class="floor_three_num">{{bioOrAll}}</div>
      </div>
      <div class="floor_three_item">
        <div class="color-f fs16 color-f fw-500">生物粒子浓度(个/s)</div>
        <!-- <div class="floor_three_num">{{item.bioConcentration}}</div> -->
        <div class="floor_three_num">{{bioConcentration}}</div>
      </div>
    </div>

    <!-- 第四层 -->
    <div class="floor_four flex row-between col-center mt16">
      <div class="floor_four_l">
        <div class="fw-500 fs16 text-center">散射光4通道粒子数(个/s)</div>
        <div class="mt10 flex flex-wrap row-between w217">
          <div class="floor_four_l_item">
            <div class="floor_four_l_item_title"> 0.5-1μm</div>
            <div style="color:#08f4da">{{parOrSum1}}</div>
          </div>
          <div class="floor_four_l_item">
            <div class="floor_four_l_item_title"> 1-2.5μm</div>
            <div style="color:#08f4da">{{parOrSum2}}</div>
          </div>
          <div class="floor_four_l_item">
            <div class="floor_four_l_item_title"> 2.5-5μm</div>
            <div style="color:#08f4da">{{parOrSum3}}</div>
          </div>
          <div class="floor_four_l_item">
            <div class="floor_four_l_item_title"> 5-10μm</div>
            <div style="color:#08f4da">{{parOrSum4}}</div>
          </div>
        </div>
      </div>

      <!-- 状态 -->
      <div class="floor_four_center">
        <img src="@/assets/img/4.0/center/shengwuWarning.png" alt="" class=" w-max h-max">
      </div>
      <div class="floor_four_l">
        <div class="fw-500 fs16 text-center">荧光4通道粒子数(个/s)</div>
        <div class="mt10 flex flex-wrap row-between w217">
          <div class="floor_four_l_item">
            <div class="floor_four_l_item_title"> 0.5-1μm</div>
            <div style="color:#08f4da">{{bioOrSum1}}</div>
          </div>
          <div class="floor_four_l_item">
            <div class="floor_four_l_item_title"> 1-2.5μm</div>
            <div style="color:#08f4da">{{bioOrSum2}}</div>
          </div>
          <div class="floor_four_l_item">
            <div class="floor_four_l_item_title"> 2.5-5μm</div>
            <div style="color:#08f4da">{{bioOrSum3}}</div>
          </div>
          <div class="floor_four_l_item">
            <div class="floor_four_l_item_title"> 5-10μm</div>
            <div style="color:#08f4da">{{bioOrSum4}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="fs16 text-center">实时警报</div>
    <div class="floor_five">
      <!-- 表头 -->
      <div class="header_table">
        <div>绑定设备名称</div>
        <div>运行状态</div>
      </div>
      <!-- 表格数据 -->
      <div style="overflow: hidden;" class="h125">
        <div class="table_box" :style="{'--updateStyle':speend+'s'}">
          <div :class="['table_item fs16',item2.useTiem==2||item2.useTiem==3?'':'table_itemBg']"
            v-for="(item2,i2) in tableList" :key="i2+'z'">
            <div class="">{{item2.deviceName}}</div>
            <div class="fw-600 color-g" v-if="item2.useTiem==2||item2.useTiem==3">运行中</div>
            <div class="fw-600 color-y" v-else>未运行</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import { aerosol } from '@/api/wang'
import { onMounted, ref, onBeforeUnmount } from 'vue'
let parOrAll = ref('0')  //总气溶胶粒子个数
let bioOrAll = ref('0')  //总荧光粒子数
let bioConcentration = ref('0')  //生物粒子浓度
//散射光4通道粒子数
let parOrSum1 = ref('0') //0.5-1μm
let parOrSum2 = ref('0') // 1-2.5μm
let parOrSum3 = ref('0') //2.5-5μm
let parOrSum4 = ref('0') //5-10μm
//荧光4通道粒子数
let bioOrSum1 = ref('0') //0.5-1μm
let bioOrSum2 = ref('0') // 1-2.5μm
let bioOrSum3 = ref('0') //2.5-5μm
let bioOrSum4 = ref('0') //5-10μm
//实时警报列表
let tableList = ref([])
//滚动速度
let speend = ref('0')
//散射光粒子个数列表
//表格列表
let list = ref([])
let timer = null //定时器
//获取气溶胶数据
const getAerosol = () => {
  aerosol().then(res => {
    list.value = res.data
    dataChage('0')
  })
}
onMounted(() => {
  getAerosol()
  timer = setInterval(() => {
    getAerosol()
  }, 180000)
})

onBeforeUnmount(() => {
  clearInterval(timer); //清除定时器
  timer = 0;
});
//轮播图切换
const change = (e) => {
  (e)
}
//数据切换赋值
const dataChage = (e) => {
  parOrAll.value = list.value[e].parOrAll
  bioOrAll.value = list.value[e].bioOrAll
  bioConcentration.value = list.value[e].bioConcentration
  parOrSum1.value = list.value[e].parOrSum1
  parOrSum2.value = list.value[e].parOrSum2
  parOrSum3.value = list.value[e].parOrSum3
  parOrSum4.value = list.value[e].parOrSum4
  bioOrSum1.value = list.value[e].bioOrSum1
  bioOrSum2.value = list.value[e].bioOrSum2
  bioOrSum3.value = list.value[e].bioOrSum3
  bioOrSum4.value = list.value[e].bioOrSum4
  if (list.value[e].statDevice.length >= 3) {
    tableList.value = [...list.value[e].statDevice, ...list.value[e].statDevice]
    speend.value = tableList.value.length
  } else {
    tableList.value = list.value[e].statDevice
  }
}
</script>

<style lang='scss' scoped>
.tixing {
  position: absolute;
  width: 620px;
  height: 548px;
  background: url("../../../assets/img/4.0/center/tixing.png") no-repeat;
  background-size: 100% 100%;
  left: 50%;
  transform: translateX(-50%);
  .title_box {
    .title_l {
      width: 8px;
      height: 22px;
      background: #f7dd70;
      border-radius: 3px;
    }
    .title_test {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #08f4da;
    }
  }
  .roomName_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    margin-top: 2px;
    padding: 0 16px;
    width: 458px;
    height: 36px;
    background: rgba(8, 244, 218, 0.3);
    border-radius: 5px;
    .room_name {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
  //第三层
  .floor_three {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 30px;
    width: 475px;
    height: 75px;
    color: #08f4da;
    .floor_three_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      .floor_three_num {
        margin-top: 10px;
        font-size: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
      }
    }
  }
  //第四层
  .floor_four {
    margin: 0 auto;
    width: 558px;
    height: 154px;
    // background: pink;
    .floor_four_l {
      height: 100%;
      .floor_four_l_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 6px;
      }
      .floor_four_l_item_title {
        width: 98px;
        height: 30px;
        border-top: 2px solid #1dbd72;
        background: linear-gradient(
          180deg,
          rgba(29, 189, 114, 0.47) 0%,
          rgba(29, 189, 114, 0) 100%
        );
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        line-height: 30px;
      }
    }
    .floor_four_center {
      width: 93px;
      height: 129px;
    }
  }
  //实时警报
  .floor_five {
    width: 556px;
    margin: 0 auto;
    margin-top: 6px;
    .header_table {
      position: relative;
      padding: 0 25px;
      height: 36px;
      border-radius: 5px;
      background: rgba(0, 249, 218, 0.3);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .table_box {
      // height: 123px;
      animation: move var(--updateStyle) linear infinite;
      .table_item {
        display: flex;
        justify-content: space-between;
        padding: 0 25px;
        height: 36px;
        line-height: 36px;
        margin-bottom: 10px;
      }
      // .table_item:last-child {
      //   margin-bottom: 0;
      // }
      .table_itemBg {
        border-radius: 5px;
        background: linear-gradient(
          90deg,
          rgba(19, 250, 239, 0) 0%,
          rgba(0, 249, 218, 0.15) 50%,
          rgba(8, 244, 218, 0) 100%
        );
      }
    }
  }
  @keyframes move {
    0% {
    }
    100% {
      transform: translateY(-50%);
    }
  }
  .table_box:hover {
    animation-play-state: paused;
  }
  .header_table ::before {
    content: " ";
    position: absolute;
    width: 8px;
    height: 22px;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #f7dd70;
    border-radius: 3px;
  }
  .header_table ::after {
    content: " ";
    position: absolute;
    width: 8px;
    height: 22px;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
    background: #f7dd70;
    border-radius: 3px;
  }
}

// .t_c_box {
//   width: 465px;
//   height: 264px;
//   background: red;
// }
// .t_l_box {
//   width: 0px;
//   height: 0px;
//   border-style: solid;
//   border-width: 0px 0px 264px 81px;
//   border-color: transparent transparent skyblue transparent;
//   // transform: rotate(-90deg);
// }
::v-deep .el-carousel__container {
  margin: 0 auto;
  width: 458px;
  height: 36px;
  border-radius: 5px !important;
}
::v-deep .el-carousel {
  margin: 0 auto;
  width: 458px;
  border-radius: 5px !important;
}
::v-deep .el-carousel__arrow {
  display: none;
}
</style>